<template>
  <div v-if="user" class="main" :class="{'main--animation': animation}">
    <div class="main__wrap">
      <img v-if="user.avatar" :src="user.avatar" class="main__wrap_avatar">
      <img v-else src="@/assets/user-none.png" class="main__wrap_avatar">
      <p class="main__wrap_name">{{ user.last_name }}</p>
      <p class="main__wrap_name">{{ user.first_name }}  {{ user.patronymic }}</p>
      <div class="main__wrap_separator"></div>
      <p v-if="user.company" class="main__wrap_light company">{{ user.company }}</p>
      <p v-if="user.position" class="main__wrap_light position">{{ user.position }}</p>


      <div class="main__wrap_row">

        <div @click="openQR" class="main__wrap_card">
          <img src="@/assets/QR.png" alt="">
          <p>QR-визитка</p>
        </div>

        <a :href="`http://87.249.49.97:67/api/cp/contacts/${this.contact_id}/export/`" class="main__wrap_card">
          <img src="@/assets/add.png" alt="">
          <p>В контакты</p>
        </a>

      </div>

      <a v-if="user.tel" class="main__wrap_button" :href="`tel:${user.tel}`">
        <img src="@/assets/phone.png" alt="">
        <p>{{ user.tel }}</p>
      </a>

      <a v-if="user.email" class="main__wrap_button" :href="`mailto:${user.email}`">
        <img src="@/assets/mail.png" alt="">
        <p>{{ user.email }}</p>
      </a>


    </div>
    <div @click="closeQR" class="qr" :class="{'qr--active': isOpenQr}">
      <div @click.stop class="qr__wrap">
        <div v-if="isOpenQr" class="qr__wrap_code">
          <img :src="user.qr_image_url" alt="">
          <!-- <VueQRCodeComponent :text="vCard" :key="qrPath" size="280" color="#000" bg-color="#fff"/> -->
          <!-- <VueQRCodeComponent text="https://www.pngrepo.com/vectors/profile/" size="280" color="#000" bg-color="#fff"/> -->
        </div>
      </div>
    </div>
  </div>
  <div v-if="error">
    <p class="main__error">Error: {{ error }}</p>
  </div>
</template>
<script>
import AuthApp from '@/components/AuthApp.vue'


import { getUser, logContactRoute }  from '@/api/getUser.js'
import VueQRCodeComponent from 'vue-qrcode-component' 
import {validPhone} from '@/utils/utils.js'

export default {
  components: {
    AuthApp,
    VueQRCodeComponent,
  },
  data(){
    return{
      animation: false,
      key: 0,
      triggerRequestModal: 0,
      blackStyle: null,
      theme: {color:"#BC07AE", grad: "linear-gradient(60.68deg, rgba(232, 1, 130, 0.758999) -28.22%, rgba(146, 0, 238, 0.961637) 28.1%, #7917E0 67.59%, rgba(132, 2, 127, 0.74) 126.05%)"},
      isOpenAuth: false,
      isLoaded: false,
      keyAuth: 0,

      contact_id: null,

      qrPath: null,

      isOpenQr: false,
      // user: {
      //   avatar: require('@/assets/complex10.jpg'),
      //   first_name: 'Иван',
      //   last_name: 'Иванов',
      //   patronymic: 'Иванович',
      //   company: 'ABBA',
      //   position: 'Дирижер',
      //   tel: '79282742421',
      //   email: 'SNDLd@mail.ru',
      //   qr_link: '',
      // },
      user: null,
      promo: null,
      vCard: '',
      error: null,
    }
  },
  provide(){
    return{
      // theme: {color: this.color, grad: this.grad},
      color: this.color,
      grad: this.gradient,
      theme: this.theme
      // color: false,
      // grad: false,
      
    }
  },
  created(){
      let query = window.location.href.split('?')
      query = query[query.length - 1]
      const entries = query.split("&").map((i) => i.split("="))
      const params = Object.fromEntries(entries);
      this.contact_id = params.contact_id
      const from_qr = params.from_qr
      console.log(this.contact_id, params,  params.contact_id);
      if(from_qr == 'true'){
        
        logContactRoute({contact_id: this.contact_id})

        const newValue = `?conatct_id=${this.contact_id}&from_qr=false`
        const newPath =
              this.$route.path[this.$route.path.length - 1] === '/'
                ? this.$route.path + `${newValue}`
                : `${this.$route.path}/${newValue}`
            history.pushState({}, null, newPath)
      }

      
      this.getData()

        if(/iPhone|iPad|iPod/i.test(navigator.userAgent)){
            // this.message = 'это iosMObile '+navigator.userAgent
            // ////console.log('это iosMObile');
            this.isMobileIOS = true
        }
    },
   methods:{
    getData(){
            if(this.blockSend){return}
            this.blockSend = true
            // alert('sendForm')
            console.log(this.contact_id);
            getUser({
                contact_id: this.contact_id,
            })
            .then((r) => {
              if(r.tel){
                r.tel = validPhone(r.tel)
              }
              this.user = r
              setTimeout(() => {
                this.animation = true
              }, 250)
              
this.vCard = `BEGIN:VCARD
VERSION:3.0
N:Lastname;Firstname
FN:${this.user.first_name} ${this.user.last_name} ${this.user.patronymic}
ORG:${this.user.company}
TITLE:${this.user.position}
ADR:
TEL;WORK;VOICE:${this.user.tel}
TEL;CELL:
TEL;FAX:
EMAIL;WORK;INTERNET:${this.user.email}
URL:
END:VCARD`

this.vCard = `BEGIN:VCARD
VERSION:3.0
N:Lastname;Firstname
FN:${this.user.first_name} ${this.user.last_name} ${this.user.patronymic}
ORG:${this.user.company}
TITLE:${this.user.position}
ADR:
TEL;WORK;VOICE:${this.user.tel}
TEL;CELL:
TEL;FAX:
URL:
END:VCARD`

console.log(this.vCard);
            })
            .catch((error) => {
                console.log(error);
                this.error = error;
                // alert(error)
            })
      },
    openQR(){
      console.log('@@');
      this.isOpenQr = true
      console.log(this.isOpenQr)
    },
    closeQR(){
      console.log('@@');
      this.isOpenQr = false
    },
   },
   watch:{
    '$route.name':{
      immediate: true,
      handler(){
        if(this.$route.name == undefined){
          return
        }
        if (this.$route.path == '/complex/12') {
          this.blackStyle = true
          this.theme.color = false
          this.theme.gradient = false
        }
        else if(this.$route.name == 'home'){
          this.theme.color = false
          this.theme.gradient = false
          this.blackStyle = false
        }
        else{
          this.blackStyle = false
          this.theme.color = "#BC07AE"
          this.theme.gradient = "linear-gradient(60.68deg, rgba(232, 1, 130, 0.758999) -28.22%, rgba(146, 0, 238, 0.961637) 28.1%, #7917E0 67.59%, rgba(132, 2, 127, 0.74) 126.05%)"

        }
      }
    },
    
   },
};
</script>

<style lang="scss">
body, #app {
  overflow: hidden !important;
}
.main{
  overflow: hidden;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  transform: translateY(20px);
  opacity: 0;
  transition: .3s;
  &--animation{
    transform: none;
    opacity: 1;
  }
  &__wrap{
    width: 320px;
    height: 600px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    box-shadow: 0px 0px 8px 5px #00000008;
    &_avatar{
      width: 130px;
      height: 130px;
      object-fit: cover;
      border-radius: 200px;
      margin-bottom: 20px;
    }
    &_name{
      @include blackBase;
      font-size: 24px;
      width: 200px;
      text-align: center;
      white-space: pre-wrap;
    }
    &_light{
      @include blackBase;
      color: #070220;
      opacity: 0.7;
      font-size: 14px;

      &.company {
        text-align: center;
        white-space: normal;
        max-width: 250px;
        font-size: 17px;
        padding-top: 4px;
      }
      &.position {
        text-align: center;
        white-space: normal;
        max-width: 250px;
        padding-top: 4px;
      }
    }
    &_row{
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 20px;
      gap: 20px;
    }
    &_card{
      display: flex;
      flex-direction: column;
      align-items: center;
      
      & > img{
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-bottom: 10px;
      }
      & > p{
        @include blackBase;
        font-size: 12px;
        color: #070220;
        opacity: 0.3;
      }
    }
    &_button{
      width: 250px;
      height: 45px;
      border-radius: 14px;
      background: #EAF1FE;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 6px;
      margin-bottom: 8px;
      transition: .1s;
      &:hover{
        background: #ccdeff;
      }
      & > img{
        width: 16px;
        height: 16px;
        margin: 10px;
        object-fit: contain
      }
      & > p{
        @include blackBase;
        font-family: 'Halvar-bd';
        letter-spacing: 0.03em;
        color: #4981B9;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  &__error{
    margin: 70px auto;
    text-align: center;
    width: 50%;
    font-size: 20px;
  }
}
.qr{
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: #00000036;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: .2s opacity;
    &--active{
      opacity: 1;
      pointer-events: all;
      .qr__wrap{
        transform: none;
      }
    }
    &__wrap{
      width: 320px;
      height: 320px;
      background-color: white;
      transition: .3s;
      transform: translateY(100vh);
      border-radius: 20px;
      overflow: hidden;
      &_code{
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

@media screen and (max-width: 800px) {
  .main {
    &__wrap{
      width: 90vw;
      height: 90vh;
      margin-bottom: 6vh;
      justify-content: center;
      padding: 2vw 4vw;
      &_avatar{
        width: 36vw;
        height: 36vw;
      }
      &_name{
        font-size: 6vw;
      }
      &_light{
        font-size: 4.2vw;
      }
      &_separator{
        height: 4vw;
      }
      &_row{
        margin-top: 4vw;
        margin-bottom: 4vw;
      }
      &_card{
        &:not(:last-child){
          margin-right: 5vw;
        }
        & > img{
          width: 18vw;
          height: 18vw;
          object-fit: contain;
        }
        & > p{
          font-size: 4vw;
        }
      }
      &_button{
        width: 80vw;
        height: 15vw;
        border-radius: 5vw;
        margin-bottom: 3vw;
        & > p{
          font-size: 3.6vw;
        }
      }
    }
  }
  .qr{
    &__wrap{
      width: 90vw;
      height: 90vw;
    }
  }
  
}

@media screen and (max-width: 380px) {
  .main{
    &__wrap{
      height: 85vh;
      margin-bottom: 10vh;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  flex-shrink: 0;
  height: 100%;
  overflow: auto;
}

// *{user-select: none}

html{

}

body{ 
  user-select: text;
  // scroll-behavior: smooth;
  margin: 0;
  min-height: 100vh;
  font-family: "Halvar";
  overflow: scroll;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
/* Указываем box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
  padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  text-align: left;
}
ul, li {
  list-style: none;
}

/* Выставляем основные настройки по-умолчанию для body */
body {
  height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
  list-style: none;
}

a{
  color: inherit;
  text-decoration: none;
  &:focus-visible{
    outline: none;
  }
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
  max-width: 100%;
  display: block;
}
button{
  border: none;
  box-shadow: none;
}

*{
  input { -webkit-user-select:text;}
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
  margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
  border: none;
  // font: inherit;
  // border: none;
}
input {
  &:focus-visible {
    outline: none;
    outline-offset: 0;
    border: none;
    outline-width: 0;
  }
}


/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
@font-face {
  font-family: "CeraPro";
  src: url("@/fonts/CeraPro.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "CeraProRegular";
  src: url("@/fonts/CeraPro-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "ArialNova";
  src: url("@/fonts/ArialNova.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "ArialBold";
  src: url("@/fonts/ArialNova-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Halvar";
  src: url("@/fonts/Halvar.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Halvar-bd";
  src: url("@/fonts/Halvar_bold.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Halvar-th";
  src: url("@/fonts/Halvar_thin.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
</style>










