export function declOfNum(n, text_forms) {
    n = Math.abs(n) % 100;
    var n1 = n % 10;
    if (n > 10 && n < 20) { return text_forms[2]; }
    if (n1 > 1 && n1 < 5) { return text_forms[1]; }
    if (n1 == 1) { return text_forms[0]; }
    return text_forms[2];
}

// export function validPhone(str) {
//     console.log();
//     let gr = str.match(/(\+\d)(\d{3})(\d{3})(\d{2})(\d{2})/);
//     let correct_phone = gr[1] + '(' + gr[2] + ')' + gr[3] + '-' + gr[4] + '-' + gr[5];
//     return str.replace(/^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/, '+$1($2)$3-$4-$5')
// }

export function validPhone(s, plus = true) {
    const startsWith = plus ? '+7' : '8';

    let phone = s.replace(/[^0-9]/g, '');
    if (phone.startsWith('7') && plus) {
        phone = phone.substr(1);
    }
    if (phone.startsWith('8')) {
        phone = phone.substr(1);
    }

    return phone.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/g, `${startsWith} ($1) $2-$3-$4`);
};
