import { useApi, postApi } from '@/api/useApi.js'

export async function getUser(context) {
    return new Promise((resolve, reject) => {
        ////console.log("&"+params);
        ////console.log(url.pathname+"/?"+params);
        let data;
        useApi(`api/cp/contacts/${context.contact_id}/`)
            .then((r) => {
                data = r.data.data;
                ////console.log("@@", data);
                resolve(data)
                ////console.log(data);
            })
            .catch((err) => {
                console.log("@@", err.response.data.description)
                reject(err.response.data.description)
            })
    })
}

export async function logContactRoute(context) {
    console.log('logContactRoute');
    return new Promise((resolve, reject) => {
        ////console.log("&"+params);
        ////console.log(url.pathname+"/?"+params);
        let data;
        postApi(`api/cp/contacts/${context.contact_id}/log/`, {})
            .then((r) => {
                data = r.data.data;
                ////console.log("@@", data);
                resolve(data)
                ////console.log(data);
            })
    })
}

