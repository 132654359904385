// import api from '../../api'
// import instance from '@/api/instance'
import {getMe} from '@/api/getMe.js'
export default {
  state: {
    user: null,
  },
  getters: {
    user: (state) => state.user,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
    clearUser(state) {
      state.user = null
    },
  },
  actions: { //dispatch outside
    // eslint-disable-next-line no-unused-vars
    async login({commit}, user) {
      await api.auth.login(user).then((response) => {
        const token = response.data.data.access_token
        sessionStorage.setItem('token', token)
        instance.defaults.headers.Authorization = `Bearer ${token}`
      })
    },
    logout({ commit }) {
      sessionStorage.removeItem('token')
      commit('clearUser')
    },
    async getCurrentUser({ commit, getters }) {
      console.log('getCurrentUser');
      return new Promise((resolve, reject) => {
        if (!getters.user) {
          const token = sessionStorage.getItem('Token')

          if (!token) {
            console.log('catch', token);
            reject(null)
          }
          getMe() 
            .then((r) => {
              commit('setUser', r)
              resolve(r)
            })
            .catch((e) => {
              console.log('catch');
              reject(e)
            })
            .finally()
        }
        else{
          resolve(getters.user)
        }
    })
    },
  },
}
