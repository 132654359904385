import axios from 'axios'

// const api = 'https://api.superapp.avacore.ru/'
// const api = 'http://94.228.1114.109:93/'
// const api = 'http://94.228.114.109:90/'
// const api = 'https://8015-46-18-206-186.ngrok-free.app/'
const api = 'http://87.249.49.97:67/'




export async function useApi(path) {
    console.log();
    return axios.get(api + path, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem('Token') } })
}

export async function postApi(path, payload) {
    return axios.post(api + path, payload, { headers: { Authorization: 'Bearer ' + sessionStorage.getItem('Token') } })
}