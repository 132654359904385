import {useApi} from '@/api/useApi.js'
// import { reject } from 'core-js/fn/promise';

export async function getMe() {
    return new Promise((resolve, reject) => {
        let data;
        useApi('api/v1/users/me/')
        .then((r) => {
            data = r.data.data
            resolve(data)
        })
        .catch(r => {
            reject(r)
        })
        
    })
}


