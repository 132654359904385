<template>
    <div class="auth">
        <div class="auth__logo">
            <img src="@/assets/AVA_logo_blue.svg" alt="">
            <!-- <p>Приемка квартир</p> -->
            
        </div>
        <div class="auth__wrap" :class="{'auth__wrap--transparent': step != 1}">
            <h2>Авторизация</h2>
            <!-- <p>Вход по номеру телефона</p> -->
            <!-- <p>Вход по номеру телефона</p> -->
            <BaseInput :type="'phone'" v-model="tel" :label="'Телефон'" :warning="telWarning"/>
            <!-- <input v-mask="'+7(###)-###-##-##'" v-model="tel" type="text" placeholder="Телефон"> -->
            <!-- <input v-model="code" type="text" placeholder="Пароль"> -->
            <button @click="sign">Запросить код</button>
        </div>

        <div class="auth__wrap" :class="{'auth__wrap--transparent': step != 2}">
            <h2>Введите код</h2>
            <!-- <p>Вход по номеру телефона</p> -->
            <input v-model="code" type="text" placeholder="Код">
            <!-- <input v-model="code" type="text" placeholder="Пароль"> -->
            <button @click="approveCode">Подтвердить</button>
        </div>
    </div>
</template>
<script>
// import { signIn } from '@/api/signIn.js'
import { sendCode } from '@/api/sendCode.js'
import BaseInput from '@/components/ui/BaseInput.vue'
export default {
    components:{
        BaseInput,
    },
    data(){
        return{
            tel: "",
            code: "",
            step: 1,

            telWarning: false,
        }
    },
    methods:{
        sign(){
            if(this.telToBack.length != 11){
                this.telWarning = "Некорректный номер телефона"
                return
            }
            else{
                this.telWarning = false
            }

            // signIn({
            //     tel: this.telToBack,
            // })
            // .then(r => {
            //     console.log(r);
            //     this.$emit('userData')
            //     this.nextStep()
            // })
        },
        nextStep(){
            this.step = 0
            setTimeout(() => {
                this.step = 2
            }, 600)
        },
        approveCode(){
            console.log('sdf');
        },
    },
    computed:{
        telToBack(){
            return this.tel.replace(/[^0-9]/g,"")
        }
    }
}
</script>
<style lang="scss">
    .auth{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: white;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &__logo{
            position: fixed;
            top: 40px;
            left: 40px;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            & > img{
                width: 58px;
                margin-right: 10px;
                margin-bottom: 10px;
            }
            & > p{
                font-size: 18px;
                line-height: 1em;
                // font-weight: bold;
                // color: var(--mainColor);
            }
        }
        &__wrap{
            align-items: center;
            display: flex;
            justify-content: center;
            flex-direction: column;
            transition: .3s;
            position: absolute;
            pointer-events: all;
            width: 330px;
            .input{
                background: #f0f1f2 !important;
            }
            &--transparent{
                opacity: 0;
                pointer-events: none;
            }
            & > h2{
                @include blackBase;
                font-size: 32px;
                padding-bottom: 20px;
                text-align: center;
            }
            // & > p{
            //     width: 100%;
            //     font-size: 18px;
            //     color: gray;
            //     text-align: center;
            //     margin-bottom: 20px;
            // }
            & > input{
                height: 50px;
                width: 330px;
                background: #f0f1f2;
                padding-left: 20px;
                border-radius: 5px;
                margin-bottom: 20px;
                font-size: 18px;
                &::placeholder{
                    color: rgb(155, 155, 155);
                }
            }
            & > button{
                @include blueButton;
                width: 330px;
                height: 50px;
                padding: 0;
            }
        }
    }
</style>